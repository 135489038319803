import React, { useState } from 'react';
import {
    IoShareSocialOutline, IoDownload, IoArrowForwardSharp, IoStar
} from "react-icons/io5";
import { Button, Card, Box, IconButton, Rating, TextField } from '@mui/material';
import Qr from '../../components/qr/Qr';
import Ratings from '../../components/ratings/Ratings';
import AppDialog from '../../components/app-dialog/AppDialog';
import Review from '../../components/review/Review';
import { ShareSocial } from 'react-share-social'
import "./Rightbar.css";
import EnquiryForm from '../../components/enquiry-form/EnquiryForm';
import AppButton from '../../components/app-button/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import ReviewFilters from '../../components/review-filters/ReviewFilters';
import { filterStoreReview, sendStoreEnquiry } from '../../reduxStore/actions/StoreActions';
import { useSnackbar } from 'notistack';
import { QR_DOWNLOAD } from '../../reduxStore/actions/StoreActionTypes';
import { SITE_NAME } from '../../constants/Site';

function Rightbar(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const allReviews = storeSelector.reviews;
    const storeId = site?.store_details?.uid;
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogSendEnqOpen, setDialogSendEnqOpen] = React.useState(false);
    const [dialogQrShareOpen, setdialogQrShareOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [activeFilter, setActiveFilter] = useState('all');
    let webUrl = `${SITE_NAME}${site?.store_details?.url}`;

    if (site?.store_details?.is_customer_portal === 1) {
        webUrl = site?.store_details?.portal_link;
    }

    const [formValues, setFormValues] = useState({
        name: '',
        mobile_no: '',
        city: '',
        email: '',
        message: '',
        store_id: storeId
    });
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const handleDialogClickOpen = () => {
        setDialogOpen(true);
    };

    const handleSendEnqDialogClickOpen = () => {
        setDialogSendEnqOpen(true);
    };

    const handleQrShareClickOpen = () => {
        setdialogQrShareOpen(true);
    };

    const handleQrDownload = () => {
        dispatch({
            type: QR_DOWNLOAD,
            payload: true
        });
    }

    const handleDialogClickClose = () => {
        setDialogOpen(false);
        setDialogSendEnqOpen(false);
        setdialogQrShareOpen(false);
        emptyFields();
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const sendEnquiry = async () => {
        try {
            setIsLoading(true);
            const obj = JSON.stringify(formValues);
            const res = await dispatch(sendStoreEnquiry(obj));
            setIsLoading(false);
            emptyFields();
            setDialogSendEnqOpen(false);
            enqueueSnackbar(res.data.message, { variant: 'success' });
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    };

    const cancelEnquiry = () => {
        setDialogSendEnqOpen(false);
        emptyFields();
    };

    const emptyFields = () => {
        setFormValues({
            name: '',
            mobile_no: '',
            city: '',
            email: '',
            message: '',
            store_id: storeId
        })
    }

    //Review Filters
    const filterReviews = async (value) => {
        console.log("value", value);
        setActiveFilter(value);
        let filterParams;
        switch (value) {
            case 'all':
                filterParams = "5,4,3,2,1";
                break;
            case 'positive':
                filterParams = "5,4";
                break;
            case 'critical':
                filterParams = "1,2";
                break;
            case '5':
                filterParams = "5";
                break;
            case '4':
                filterParams = "4";
                break;
            case '3':
                filterParams = "3";
                break;
            case '2':
                filterParams = "2";
                break;
            case '1':
                filterParams = "1";
                break;
        }
        try {
            setIsLoading(true);
            const obj = JSON.stringify({
                store_id: storeId,
                filter_para: filterParams
            });
            const res = await dispatch(filterStoreReview(obj));
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const dialogEnqFooter = (
        <>
            <Box style={{
                display: 'flex',
                gap: 15,
            }}>
                <AppButton
                    size='medium'
                    onClick={cancelEnquiry}
                    style={{
                        color: configs?.primaryButtonBgColor
                    }}
                >
                    Cancel
                </AppButton>
                <AppButton
                    onClick={sendEnquiry}
                    disabled={isLoading}
                    isLoading={isLoading}
                    variant="contained"
                    style={{
                        backgroundColor: configs?.primaryButtonBgColor,
                        color: configs?.primaryButtonColor,
                        minWidth: 100
                    }}
                    size='medium'>
                    Submit
                </AppButton>
            </Box>
        </>
    )

    const dialogTitle = (
        <ReviewFilters activeFilter={activeFilter} filterReviews={filterReviews} />
    );

    const dialogSendEnqTitle = (
        <>
            <Box>Send Enquiry</Box>
        </>
    );

    return (
        <>
            <Box>
                <Card>
                    <div className='v1-store-qrwrap'>
                        <div className='v1-store-qrTitle'>QR code of {site?.store_details?.store_name}</div>
                        <div className='v1-store-qrwrapInner'>
                            <Qr size={220} />
                        </div>
                        <div className='v1-store-qrAction'>
                            <div>
                                <Button
                                    onClick={handleQrShareClickOpen}
                                    startIcon={<IoShareSocialOutline />}
                                    size="small"
                                    variant="outlined">
                                    Share QR
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={handleQrDownload}
                                    startIcon={<IoDownload />}
                                    variant="contained"
                                    size="small"
                                    color="info">
                                    Download
                                </Button>
                            </div>
                        </div>
                    </div>
                </Card>
                {configs?.showReviews &&
                    <Card style={{
                        marginTop: 20
                    }}>
                        <div className='v1-store-reviewWrap'>
                            <div className='v1-store-reviewTitle'>
                                <div>Ratings and reviews</div>
                                <Box>
                                    <IconButton onClick={handleDialogClickOpen}><IoArrowForwardSharp size={20} color="#000" /></IconButton>
                                </Box>
                            </div>
                            <Ratings size='small' />
                        </div>
                    </Card>
                }
                <Box style={{
                    marginTop: 20
                }}>
                    <AppButton fullWidth variant="contained" style={{
                        backgroundColor: configs?.primaryButtonBgColor,
                    }} onClick={handleSendEnqDialogClickOpen}>
                        Send Enquiry
                    </AppButton>
                </Box>
            </Box>

            {dialogOpen &&
                <AppDialog
                    isOpen={dialogOpen}
                    id={'store_right_review_sm'}
                    handleClickClose={handleDialogClickClose}
                    title={dialogTitle}>
                    {allReviews && allReviews?.map((review) => {
                        return (
                            <Review
                                review={review}
                                key={review?.uid}
                            />
                        )
                    })}
                </AppDialog>
            }

            {dialogSendEnqOpen &&
                <AppDialog
                    isOpen={dialogSendEnqOpen}
                    id={'store_right_send_enq_sm'}
                    handleClickClose={handleDialogClickClose}
                    title={dialogSendEnqTitle}
                    footer={dialogEnqFooter}
                >
                    <EnquiryForm formVals={formValues} handleChange={handleChange} />
                </AppDialog>
            }

            {dialogQrShareOpen &&
                <AppDialog isOpen={dialogQrShareOpen} id={'store_right_qr_share_sm'} handleClickClose={handleDialogClickClose} title={<>Share QR</>}>
                    <Box className="store-social-share">
                        <ShareSocial
                            url={webUrl}
                            socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
                        />
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default Rightbar;