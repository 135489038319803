import React from 'react';
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import "./PostCard.css";
import { useSelector } from 'react-redux';
import AppButton from '../app-button/AppButton';
import { Link } from 'react-router-dom';
import { IMAGE_URL } from '../../constants/Images';

function PostCard({ post }) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const storeId = site?.store_details?.uid;

    const cleanPost = (value) => {
        const regex = /(<([^>]+)>)/gi;
        return value?.replace(regex, "");
    }

    return (
        <Box>
            <Card>
                <Link to={`/post/details/${post.uid}`}>
                    <CardActionArea>
                        {post?.images.length > 0 &&
                            <CardMedia
                                component="img"
                                height="180"
                                image={`${IMAGE_URL}/store/post/thumbnail?id=${storeId}&img_id=${post?.images[0]?.image_path}`}
                                alt={post?.title}
                            />
                        }
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                {post?.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                <Box dangerouslySetInnerHTML={{ __html: `${cleanPost(post?.description)?.substring(0, 100)}...` }} />
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Link>
                <CardActions>
                    {/* <AppButton
                        size="small"
                        style={{
                            color: configs?.themeBgColor
                        }}>
                        Share
                    </AppButton> */}
                </CardActions>
            </Card>
        </Box>
    );
}

export default PostCard;