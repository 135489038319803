import { Box, Button, Card, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGE_URL, NO_IMAGE } from '../../constants/Images';
import { PRIMARY_ACTION } from '../../reduxStore/actions/StoreActionTypes';
import AppButton from '../app-button/AppButton';
import "./ItemList.css";
import { WHATSAPP_URL } from '../../constants/Site';

function ItemList({ item, itemClick }) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const variantItem = item?.variants ? item?.variants[0] : {};
    const storeId = site?.store_details?.uid;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    // const goToItemDetails = async (row) => {
    //     navigate(`/item-details/${row.uid}`, { state: row });
    // }
    const image = item?.all_iamges?.length
        ? `${IMAGE_URL}item/thumbnail?id=${storeId}&img_id=${item?.all_iamges[0]?.image_path}`
        : NO_IMAGE;

    const itemAction = (item) => {
        if (site?.store_details?.is_what_app_enquiry === 1) {
            const actionMsg = `${site?.store_details?.action_btn_msg} ${item.name}`;
            window.open(`${WHATSAPP_URL}?phone=${site?.store_details?.whatsapp_no}&text=${actionMsg}`, '_blank');
        } else {
            dispatch({
                type: PRIMARY_ACTION,
                payload: { item: item, showModal: true, isOtpMandatory: true }
            });
        }
    }

    const getFormatedPrice = (val) => {
        if (val >= 10000000) val = (val / 10000000).toFixed(2) + 'Cr';
        else if (val >= 100000) val = (val / 100000).toFixed(2) + 'L';
        return val;
    }

    let per_variant = `${variantItem?.variant_value} ${variantItem?.unit_name}`;
    return (
        <Box style={{
            marginTop: 20
        }}>
            <Card>
                <Box className='list-item-list'>
                    <Box className='list-item-images'>
                        <Box onClick={() => itemClick(item)} className='list-item-images-bg' style={{
                            backgroundImage: `url(${image})`
                        }}>
                            {
                                variantItem?.discount ?
                                    <Box className='list-item-offer'>{variantItem?.discount}</Box>
                                    :
                                    <></>
                            }
                            {item?.all_iamges?.length > 0 &&
                                <Box className='sPhotoCount'>
                                    {item?.all_iamges?.length}+ Photos
                                </Box>
                            }
                        </Box>
                    </Box>
                    <Box className='list-item-body'>
                        <Box className='list-item-body-wrap' onClick={() => itemClick(item)}>
                            <Box className='list-item-name'>
                                <Link>{item?.name}</Link>
                            </Box>
                            <Box className='list-item-unit'>{per_variant}</Box>
                            {item?.brand_name &&
                                <Box className='list-item-brand' style={{
                                    background: configs?.themeBgColor,
                                    color: configs?.themeColor
                                }}>{item?.brand_name}</Box>
                            }
                            <Typography className='list-item-desc-txt'>Description:</Typography>
                            <Box className='list-item-desc'>{item?.short_description}</Box>
                        </Box>
                        <Box className='list-item-price-wrap'>
                            <Box className='list-item-price' onClick={() => itemClick(item)}>
                                <Typography className='list-item-price-actual' style={{
                                    textDecoration: variantItem?.discounted_price && 'line-through'
                                }}>
                                    {variantItem?.original_price ? `Rs.${getFormatedPrice(variantItem?.original_price)}` : ''}
                                </Typography>
                                <Typography className='list-item-price-discounted'>
                                    {variantItem?.discounted_price ? `Rs.${getFormatedPrice(variantItem?.discounted_price)}` : ''}
                                </Typography>
                            </Box>
                            <Box className='list-item-btn'>
                                {configs?.showPrimaryButton &&
                                    <AppButton onClick={() => itemAction(item)} style={{
                                        backgroundColor: configs?.primaryButtonBgColor,
                                        color: configs?.primaryButtonColor
                                    }} size='small' variant='contained'>
                                        {configs?.primaryButtonTitle}
                                    </AppButton>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Card >
        </Box >
    );
}

export default ItemList;