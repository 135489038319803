import { Box, Card, IconButton, ImageList, ImageListItem, Typography } from '@mui/material';
import FsLightbox from 'fslightbox-react';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import AppButton from '../../components/app-button/AppButton';
import Iconify from '../../components/iconify/Iconify';
import { IMAGE_URL } from '../../constants/Images';
import "./PostDetails.css";

function PostDetails(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const storeId = site?.store_details?.uid;
    const storeUrl = site?.store_details?.url;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const [lightboxController, setLightboxController] = React.useState({
        toggler: false,
        slide: 1
    });
    const { url } = useParams();

    const activePost = storeSelector?.site?.posts?.find(item => item.uid === url);

    function openLightboxOnSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number + 1
        });
    }

    const itemData = [];
    const sourceData = [];
    if (activePost?.images?.length > 0) {
        activePost?.images?.forEach((element, key) => {
            sourceData.push(`${IMAGE_URL}store/post?id=${storeId}&img_id=${element.image_path}`);
            itemData.push({
                img: `${IMAGE_URL}store/post/thumbnail?id=${storeId}&img_id=${element.image_path}`,
                title: activePost?.title,
                featured: (key % 4 === 0) ? true : false,
            });
        });
    }

    function srcset(image, width, height, rows = 1, cols = 1) {
        return {
            // src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
            // srcSet: `${image}?w=${width * cols}&h=${height * rows
            //     }&fit=crop&auto=format&dpr=2 2x`,
            src: `${image}`,
            srcSet: `${image}`,
        };
    }

    return (
        <Box>
            <Helmet>
                <style>{`body { background-color: ${configs?.pageBackground} }`}</style>
                <title>{site?.store_details?.store_name ? `${site?.store_details?.store_name} Posts` : 'Posts'}</title>
            </Helmet>
            <Box className='page-container'>
                <Box className='page-details-page'>
                    <Box>
                        <Box>
                            <Typography gutterBottom>
                                <Link style={{ textDecoration: 'none' }} to="/posts">
                                    <IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton>
                                    Back
                                </Link>
                            </Typography>
                        </Box>
                        <Card>
                            <Box className='post-details-title'>
                                <Typography style={{
                                    fontSize: 18,
                                    fontWeight: 600
                                }}>{activePost?.title}</Typography>
                                <Typography style={{
                                    fontSize: 13,
                                }}>Posted on: {activePost?.created_at}</Typography>
                            </Box>
                            <Box>
                                <ImageList
                                    sx={{
                                        transform: 'translateZ(0)',
                                        overflowY: 'inherit'
                                    }}
                                    rowHeight={200}
                                    gap={2}
                                    className='post-d-image-wrap'
                                >
                                    {itemData.length > 0 && itemData?.map((item, index) => {
                                        const cols = item.featured ? 2 : 1;
                                        const rows = item.featured ? 2 : 1;

                                        return (
                                            <ImageListItem key={item.img} cols={cols} rows={rows}
                                                onClick={() => openLightboxOnSlide(index)}>
                                                <img
                                                    {...srcset(item.img, 250, 200, rows, cols)}
                                                    alt={item.title}
                                                    loading="lazy"
                                                />
                                            </ImageListItem>
                                        );
                                    })}
                                </ImageList>
                            </Box>
                            <Box className='post-details-desc'>
                                <Typography style={{
                                    fontSize: 15
                                }}>
                                    <Box dangerouslySetInnerHTML={{ __html: activePost?.description }} />
                                </Typography>
                                <Box style={{
                                    marginTop: 20
                                }}>
                                    {/* <AppButton
                                        size="small"
                                        style={{
                                            color: configs?.themeBgColor
                                        }}>
                                        Share
                                    </AppButton> */}
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Box>

            <FsLightbox
                toggler={lightboxController.toggler}
                sources={sourceData}
                slide={lightboxController.slide}
            />

        </Box>
    );
}

export default PostDetails;