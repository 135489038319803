import { Box, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import "./EnquiryForm.css";

function EnquiryForm({ formVals, handleChange }) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    return (
        <Box style={{
            marginTop: 30,
            marginBottom: 30,
            display: 'flex',
            flexDirection: 'column',
            gap: 15
        }}>
            <Box>
                <TextField
                    autoComplete='off'
                    value={formVals?.name}
                    name='name'
                    label="Name*"
                    fullWidth
                    onChange={handleChange} />
            </Box>
            <Box>
                <TextField
                    autoComplete='off'
                    label="Mobile No*"
                    name="mobile_no"
                    fullWidth
                    value={formVals?.mobile_no}
                    onChange={handleChange} />

            </Box>
            <Box>
                <TextField
                    autoComplete='off'
                    label="Email (Optional)"
                    name="email"
                    fullWidth
                    value={formVals?.email}
                    onChange={handleChange} />
            </Box>
            <Box>
                <TextField
                    autoComplete='off'
                    label="City*"
                    name="city"
                    fullWidth
                    value={formVals?.city}
                    onChange={handleChange} />
            </Box>
            <Box>
                <TextField
                    autoComplete='off'
                    label="Message (Optional)"
                    name="message"
                    multiline rows={2}
                    fullWidth
                    value={formVals?.message}
                    onChange={handleChange} />
            </Box>
        </Box>
    );
}

export default EnquiryForm;