import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import React from 'react';
import "./AppDialogDelete.css";

function AppDialogDelete({ isOpen, id, title, children, footer }) {
    return (
        <>
            <Dialog
                style={{
                    borderRadius: 16
                }}
                open={isOpen}
                maxWidth='xs'
                aria-labelledby={id}
            >
                <DialogTitle id={id}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{
                    padding: 24
                }}>
                    {footer}
                </DialogActions>
            </Dialog>
        </>
    );
}

export default AppDialogDelete;