import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { IMAGE_URL, NO_IMAGE } from '../../constants/Images';

function PhotoList({ row, openImageViwer }) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const storeId = site?.store_details?.uid;

    const backgroundImage = row?.images?.length > 0 ? `${IMAGE_URL}label/thumbnail?id=${storeId}&img_id=${row?.images[0].url_path}` : NO_IMAGE;
    const lightImages = [];
    if (row?.images?.length) {
        row?.images.forEach((img) => {
            const path = `${IMAGE_URL}label?id=${storeId}&img_id=${img.url_path}`
            lightImages.push(path);
        })
    }

    return (
        <>
            <Box>
                <Box className='sPhoto'
                    style={{
                        backgroundImage: `url(${backgroundImage})`
                    }}
                    onClick={() => row?.images?.length ? openImageViwer(lightImages, row?.uid) : undefined}
                >
                    <Box className='sPhotoCount'>
                        {row?.images?.length}+ Photos
                    </Box>
                </Box>
                <Box className='sPhotoName'>{row?.label}</Box>
            </Box>
        </>
    );
}

export default PhotoList;