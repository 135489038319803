import { Box, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import OffersCard from '../../components/offers-card/OffersCard';
import "./Offers.css";

function Offers(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};

    return (
        <>
            <Helmet>
                <style>{`body { background-color: ${configs?.pageBackground} }`}</style>
                <title>{site?.s ? `${site?.store_details?.store_name} Offers` : 'Offers'}</title>
            </Helmet>
            <Box className='page-container'>
                <Box className='offer-page'>
                    <Typography className='home-category-title'>Offers</Typography>
                    <Box>
                        {site?.offers &&
                            <Box className='offer-page-wrap'>
                                {site.offers.map((offer) => {
                                    return (
                                        <OffersCard
                                            offer={offer}
                                            key={offer.uid}
                                            style={{
                                                padding: 15,
                                                textAlign: 'center',
                                            }} />
                                    )
                                })}
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    );
}

export default Offers;