import React from 'react';
import Rating from '@mui/material/Rating';
import "./Ratings.css";
import { useSelector } from 'react-redux';

function Ratings({ size = 'medium' }) {
    const storeSelector = useSelector((state) => state.sStore);
    const configs = storeSelector.configs;
    const site = storeSelector.site;
    return (
        <div>
            <div className='ratingWrap'>
                <div className='ratingBox'>
                    <div className='rateCount'>{site?.reviews?.rating ? site?.reviews?.rating : 0}</div>
                    <Rating size={size} name="read-only" precision={0.5} value={site?.reviews?.rating ? site?.reviews?.rating : 0} readOnly />
                    <div className='rateCaption'> {site?.reviews?.reviewCount ? site?.reviews?.reviewCount : 0} reviews</div>
                </div>
                <div className='reviewBox'>
                    <div className='reviewBar'>
                        <div className="reviewCount">5</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(site?.reviews?.count?.fiveRatingCount / site?.reviews?.reviewCount) * 100}%`
                                }}
                                title={site?.reviews?.count?.fiveRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">4</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(site?.reviews?.count?.fourRatingCount / site?.reviews?.reviewCount) * 100}%`
                                }}
                                title={site?.reviews?.count?.fourRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">3</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(site?.reviews?.count?.threeRatingCount / site?.reviews?.reviewCount) * 100}%`
                                }}
                                title={site?.reviews?.count?.threeRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">2</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(site?.reviews?.count?.twoRatingCount / site?.reviews?.reviewCount) * 100}%`
                                }}
                                title={site?.reviews?.count?.twoRatingCount}></div>
                        </div>
                    </div>
                    <div className='reviewBar'>
                        <div className="reviewCount">1</div>
                        <div className='reviewBarDefault'>
                            <div className="reviewBarPer"
                                style={{
                                    width: `${(site?.reviews?.count?.oneRatingCount / site?.reviews?.reviewCount) * 100}%`
                                }}
                                title={site?.reviews?.count?.oneRatingCount}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ratings;