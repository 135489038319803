import { Box, Button, IconButton, Typography } from '@mui/material';
import React from 'react';
import { IoDownload, IoShareSocialOutline } from 'react-icons/io5';
import Iconify from '../iconify/Iconify';
import AppDialog from '../app-dialog/AppDialog';
import Qr from '../qr/Qr';
import { useDispatch, useSelector } from 'react-redux';
import { APP_SHARE, QR_DOWNLOAD, QR_HEADER_DOWNLOAD } from '../../reduxStore/actions/StoreActionTypes';

function QrSm(props) {
    const dispatch = useDispatch();
    const storeSelector = useSelector((state) => state.sStore);
    const configs = storeSelector.configs;
    const site = storeSelector.site;

    const [QROpen, setQROpen] = React.useState(false);

    const handleQRClickOpen = () => {
        setQROpen(true);
    };
    const handleQRClickClose = () => {
        setQROpen(false);
    };
    const openQrShare = () => {
        setQROpen(false);
        dispatch({
            type: APP_SHARE,
            payload: true
        });
    };

    const handleQrDownload = () => {
        dispatch({
            type: QR_HEADER_DOWNLOAD,
            payload: true
        });
    }

    const dialogTitle = (
        <Typography style={{
            fontSize: 15,
            fontWeight: 500,
            color: '#000'
        }}>QR code of {site?.store_details?.store_name}</Typography>
    );

    return (
        <>
            <IconButton onClick={handleQRClickOpen}>
                <Iconify color="#000" icon={'material-symbols:qr-code'} style={{
                    height: 25,
                    width: 25
                }} />
            </IconButton>

            {QROpen &&
                <AppDialog isOpen={QROpen} id={'qr_dialog_sm'} handleClickClose={handleQRClickClose} title={dialogTitle}>
                    <Box style={{
                        maxWidth: 300,
                        margin: '0 auto',
                        marginTop: 20,
                        padding: 10,
                        textAlign: 'center'
                    }}>
                        <Box>
                            <Qr size={280} />
                        </Box>
                        <Box style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            marginTop: 20
                        }}>
                            <Box>
                                <Button
                                    onClick={openQrShare}
                                    startIcon={<IoShareSocialOutline />}
                                    size="medium"
                                    variant="outlined">
                                    Share QR
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    onClick={handleQrDownload}
                                    startIcon={<IoDownload />}
                                    variant="contained"
                                    size="medium"
                                    color="info">
                                    Download
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </AppDialog>
            }
        </>
    );
}

export default QrSm;