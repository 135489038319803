import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import "./OffersCard.css";

function OffersCard({ offer, style }) {
    return (
        <Box className='offers-card-list'>
            <Card>
                <Box style={{
                    ...style
                }} className='offers-card-list-wrap' >
                    <Typography style={{
                        fontSize: 15,
                        fontWeight: 600
                    }}>{offer?.title}</Typography>
                    <Typography style={{
                        fontSize: 12
                    }}>Valid till {offer?.till_date}</Typography>
                </Box>
            </Card>
        </Box>
    );
}

export default OffersCard;