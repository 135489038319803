import { useNavigate } from "react-router-dom";
import axiosInstance from "../../api/axios";
import { LOGGEDIN_USER_DETAILS, LOGIN, LOGOUT } from "./AuthTypes";

export const auth = (country_code, mobile_no) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/login", { country_code, mobile_no });
        dispatch({
            type: LOGIN,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const sendOtp = (country_code, mobile_no, otp, otp_reference_id) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/verify_otp", {
            country_code, mobile_no, otp, otp_reference_id
        });
        dispatch({
            type: LOGGEDIN_USER_DETAILS,
            payload: res.data,
        });
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};

export const updateName = (name) => async (dispatch) => {
    try {
        const res = await axiosInstance.post("/user/update-name", {
            name
        });
        dispatch(updateUserSession(name));
        return Promise.resolve(res);
    } catch (err) {
        return Promise.reject(err);
    }
};


export const setUserSession = (res) => async (dispatch) => {
    await localStorage.setItem('userInfo', JSON.stringify(res.data));
    await localStorage.setItem('access_token', res.data.access_token);
}

export const updateUserSession = (name) => async (dispatch) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
        const userDetails = JSON.parse(userInfo);
        userDetails.name = name;
        localStorage.setItem('userInfo', JSON.stringify(userDetails));
        dispatch({
            type: LOGGEDIN_USER_DETAILS,
            payload: userDetails,
        });
    }
}

export const updateUserSessionProfile = (obj) => async (dispatch) => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
        const userDetails = JSON.parse(userInfo);
        userDetails.name = obj?.name;
        userDetails.email = obj?.email;
        userDetails.profile_pic = obj?.profile_pic;
        userDetails.profile_bio = obj?.profile_bio;
        userDetails.city = obj?.city;
        userDetails.pincode = obj?.pincode;
        localStorage.setItem('userInfo', JSON.stringify(userDetails));
        dispatch({
            type: LOGGEDIN_USER_DETAILS,
            payload: userDetails,
        });
    }
}


export const getUserSession = () => {
    return localStorage.getItem('userInfo');
}

export const getAccessToken = () => {
    const userInfo = localStorage.getItem('userInfo');
    const accessToken = '';
    if (userInfo) {
        accessToken = JSON.parse(userInfo)?.access_token;
    }
    return accessToken;
}

export const logout = () => async (dispatch) => {
    await localStorage.removeItem('userInfo');
    await localStorage.removeItem('access_token');
    dispatch({
        type: LOGOUT
    });
}