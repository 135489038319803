import { Dialog, DialogActions, DialogContent, DialogTitle, Grow, IconButton, Slide } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';
import "./AppDialogSlide.css";
import useCheckMobileScreen from '../../utils/useCheckMobileScreen';

const TransitionSlide = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const TransitionGrow = React.forwardRef(function Transition(props, ref) {
    return <Grow direction="up" ref={ref} {...props} />;
});

function AppDialogSlide({ isOpen, id, handleClickClose, title, children, footer }) {
    const isMobile = useCheckMobileScreen();
    function BootstrapDialogTitle(props) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, pt: 2, pb: 1, pl: 2, pr: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <FiX />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func,
    };
    return (
        <>
            <Dialog
                onClose={handleClickClose}
                aria-labelledby={id}
                open={isOpen}
                maxWidth='xs'
                className='app-dialog-slide'
                TransitionComponent={isMobile ? TransitionSlide : TransitionGrow}
            >
                <BootstrapDialogTitle id={id} onClose={handleClickClose}>
                    {title}
                </BootstrapDialogTitle>
                <DialogContent className='app-dialog-content'>
                    {children}
                </DialogContent>
                {footer &&
                    <DialogActions style={{
                        padding: 20
                    }}>
                        {footer}
                    </DialogActions>
                }
            </Dialog>
        </>
    );
}
export default AppDialogSlide;