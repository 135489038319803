import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import SiteLayout from '../layouts/SiteLayout';
import About from '../pages/about/About';
import Home from '../pages/home/Home';
import ItemDetails from '../pages/item-details/ItemDetails';
import Offers from '../pages/offers/Offers';
import Search from '../pages/search/Search';
import UserProfile from '../pages/users/UserProfile';
import { getUserSession } from '../reduxStore/actions/AuthActions';
import { LOGGEDIN_USER_DETAILS, LOGOUT } from '../reduxStore/actions/AuthTypes';
import { APP_LOADING, CONFIG } from '../reduxStore/actions/StoreActionTypes';
import ProtectedRoutes from './ProtectedRoutes';
import Posts from '../pages/posts/Posts';
import PostDetails from '../pages/post-details/PostDetails';
import { useSnackbar } from 'notistack';
import { getStoreData } from '../reduxStore/actions/StoreActions';
import { cloneDeep } from 'lodash';
function Routers(props) {

    const configs = window.configs;
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const storeSelector = useSelector((state) => state.sStore);
    const storeUrl = storeSelector?.configs?.store_url;
    //Call store api
    const getStore = async () => {
        if (storeUrl) {
            try {
                await dispatch(getStoreData(storeUrl));
                dispatch({
                    type: APP_LOADING,
                    payload: false
                });
            } catch (err) {
                dispatch({
                    type: APP_LOADING,
                    payload: false
                });
                if (err?.errors) {
                    enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
                }
            }
        } else {
            //enqueueSnackbar('Store config is missing', { variant: 'error' });
        }
    }

    const loggedInDetails = async () => {
        const userDetails = await getUserSession();
        if (!userDetails) {
            dispatch({
                type: LOGOUT
            });
            return;
        }
        const userSession = JSON.parse(userDetails);
        dispatch({
            type: LOGGEDIN_USER_DETAILS,
            payload: userSession
        });
    }

    const setStoreDetails = async () => {
        await dispatch({
            type: CONFIG,
            payload: cloneDeep(configs)
        });
    }

    useEffect(() => {
        setStoreDetails();
        loggedInDetails();
    }, []);

    useEffect(() => {
        getStore();
    }, [storeUrl]);

    return (
        <Routes>
            <Route element={<SiteLayout />}>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/posts" element={<Posts />} />
                <Route path="/post/details/:url" element={<PostDetails />} />
                <Route path="/user/profile" element={<UserProfile />} />
            </Route>
            <Route path="/items" element={<>Contact</>} />
            <Route path="/item-details/:id" element={<ItemDetails />} />
            <Route path="/search" element={<Search />} />
            <Route path='*' element={<div>Not found</div>} />
        </Routes>
    );
}

export default Routers;


//<Link to="/books/1">Link 1</Link>
//<Link to="{`/books/${number}`}">Link 1</Link>