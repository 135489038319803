import { Box, Button, IconButton } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import AccountSm from '../account-sm/AccountSm';
import QrSm from '../qr-sm/QrSm';
import Logo from '../logo/Logo';
import "./Header.css";
import Iconify from '../iconify/Iconify';
import AppDialogSlide from '../app-dialog-slide/AppDialogSlide';
import { APP_SHARE } from '../../reduxStore/actions/StoreActionTypes';
import { ShareSocial } from 'react-share-social'
import { SITE_NAME } from '../../constants/Site';

function Header(props) {

    const auth = useSelector((state) => state.sAuth);
    const accessToken = auth?.userDetails?.access_token;
    const [value, setValue] = React.useState();
    const location = useLocation();
    const pathLocation = location.pathname;
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const storeUrl = site?.store_details?.url;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const shareDialog = storeSelector.isShare;
    let webUrl = `${SITE_NAME}${site?.store_details?.url}`;

    if (site?.store_details?.is_customer_portal === 1) {
        webUrl = site?.store_details?.portal_link;
    }
    
    const dispatch = useDispatch();

    const handleTabChange = () => {
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/about':
                setValue(1);
                break;
            case '/offers':
                setValue(2);
                break;
            case '/posts':
                setValue(3);
                break;
        }
    }


    const handleDialogClickClose = (index) => {
        dispatch({
            type: APP_SHARE,
            payload: false
        });
    };

    React.useEffect(() => {
        handleTabChange();
    }, [pathLocation]);

    return (
        <div className='page-header'>
            <div className='page-header-content'>
                <Logo color="rgb(0, 0, 0)" />
                <div className='h-right-wrap'>
                    <ul>
                        <li className='header-book-demo-sm' style={{
                            fontWeight: value === 2 && 700
                        }}>
                            <Link style={{
                                color: value === 2 && configs?.primaryButtonBgColor
                            }} to='/offers'>Offers</Link>
                        </li>
                        <li className='header-book-demo-sm' style={{
                            fontWeight: value === 3 && 700
                        }}>
                            <Link style={{
                                color: value === 3 && configs?.primaryButtonBgColor
                            }} to='/posts'>Posts</Link>
                        </li>
                        <li className='header-book-demo-sm' style={{
                            fontWeight: value === 1 && 700
                        }}>
                            <Link style={{
                                color: value === 1 && configs?.primaryButtonBgColor
                            }} to='/about'>About</Link>
                        </li>
                        <li>
                            <Link to='/search'>
                                <IconButton>
                                    <Iconify color="#000" icon={'carbon:search'} style={{
                                        height: 25,
                                        width: 25
                                    }} />
                                </IconButton>
                            </Link>
                        </li>
                        <li>
                            <QrSm />
                        </li>
                        <li className='header-book-demo-sm'>
                            <AccountSm />
                        </li>
                    </ul>
                    {/* <div className='profile-icon'></div> */}
                </div>
            </div>

            {shareDialog &&
                <AppDialogSlide
                    isOpen={shareDialog}
                    id={'home_share_d'}
                    title={<>Share</>}
                    footer={<></>}
                    handleClickClose={handleDialogClickClose}>
                    <Box>
                        <ShareSocial
                            url={webUrl}
                            socialTypes={['whatsapp', 'facebook', 'twitter', 'telegram']}
                        />
                    </Box>
                </AppDialogSlide>
            }
        </div>

    );
}

export default Header;