import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { Colors } from '../../constants/Colors';

function AppButton({ size = 'medium', startIcon, isLoading, variant, fullWidth, disabled, onClick, children, style, className }) {

    const loading = (
        <Box style={{
            display: 'flex',
            gap: 12,
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <CircularProgress size={20} style={{
                color: 'rgba(145, 158, 171, 0.8)'
            }} />
            <Box>Loading...</Box>
        </Box>
    )
    return (
        <Button className={className} startIcon={startIcon} size={size} variant={variant} disabled={disabled} fullWidth={fullWidth} onClick={onClick}
            style={{
                backgroundColor: variant !== 'contained' || disabled ? '' : Colors.primary,
                ...disabled ? '' : style
            }}
        >
            {isLoading ? loading : children}
        </Button>
    );
}

export default AppButton;