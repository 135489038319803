import axios from "axios";
import { SITE_NAME } from "../constants/Site";
import { getAccessToken, logout } from "../reduxStore/actions/AuthActions";
const baseURL = `${SITE_NAME}apis/v1/api`;

const axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
});

axiosInstance.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response.data;
    },
    error => {
        if (error.code === 'ERR_NETWORK') {
            console.log("No internet connection.");
            return Promise.reject(error);
        }
        const status = error.response?.status || 500;
        switch (status) {
            case 401: {
                //authentication issue
                //store.dispatch(logout());
            }
            case 403: {
                // forbidden (permission related issues)
            }
            case 404: {
                // not found
            }
            default: {
                if (error.response && error.response.data) {
                    return Promise.reject(error.response.data);
                }
                return Promise.reject(error)
            }
        }
    });
export default axiosInstance;