import {
    APP_LOADING,
    CONFIG,
    SITE_DATA,
    APP_SHARE,
    PRIMARY_ACTION,
    ALL_REVIEWS,
    UPDATE_REVIEW_RECOMMENDATIONS,
    QR_DOWNLOAD,
    QR_HEADER_DOWNLOAD
} from "../actions/StoreActionTypes";

const initialState = {
    configs: {},
    appLoading: true,
    isQrDownload: false,
    isQrHeaderDownload: false,
    isShare: false,
    isLoading: false,
    site: {},
    primaryAction: {},
    reviews: []
};
function SStoreReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case CONFIG:
            return {
                ...state,
                configs: payload
            };
        case APP_LOADING:
            return {
                ...state,
                appLoading: payload
            };
        case APP_SHARE:
            return {
                ...state,
                isShare: payload
            };
        case QR_DOWNLOAD:
            return {
                ...state,
                isQrDownload: payload
            };
        case QR_HEADER_DOWNLOAD:
            return {
                ...state,
                isQrHeaderDownload: payload
            };
        case SITE_DATA:
            return {
                ...state,
                site: payload
            };
        case PRIMARY_ACTION:
            return {
                ...state,
                primaryAction: payload
            };
        case ALL_REVIEWS:
            return {
                ...state,
                reviews: payload
            };
        case UPDATE_REVIEW_RECOMMENDATIONS:
            return {
                ...state,
                site: {
                    ...state.site,
                    reviews: payload?.reviews,
                    recommendation: payload?.recommendation
                }
            };
        default:
            return state;
    }

};

export default SStoreReducer;