import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function ItemVariant({ item, index, variantItemSelection }) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};

    const variantItems = item?.optionValues;
    return (
        <Box className='item-d-variant-wrap'>
            {variantItems && variantItems.map((variant, i) => {
                return (
                    <Box className='item-d-variant-item' key={i}
                        style={{
                            color: variant === item?.selectedOption && configs?.primaryButtonBgColor,
                            borderColor: variant === item?.selectedOption && configs?.primaryButtonBgColor
                        }} onClick={() => variantItemSelection(variant, index)}>
                        <Box>{variant}</Box>
                    </Box>
                )
            })
            }
        </Box>
    );
}

export default ItemVariant;