import { Box, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import EnquiryFormDialog from '../../components/enquiry-form-dialog/EnquiryFormDialog';
import Iconify from '../../components/iconify/Iconify';
import ItemList from '../../components/item-list/ItemList';
import "./Search.css";


function Search(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const storeId = site?.store_details?.uid;
    const [allItems, setAllItems] = React.useState([]);
    const [allItemsList, setAllItemsList] = React.useState([]);
    const [isItemClick, setIsItemClick] = React.useState(false);
    const navigate = useNavigate();

    const setSearch = async () => {
        const searchData = [];
        if (site?.categories?.length > 0) {
            await site?.categories?.forEach(element => {
                if (element?.items.length > 0) {
                    element?.items.forEach(item => {
                        searchData.push(item);
                    });
                }
            });
            setAllItems(searchData);
            setAllItemsList(searchData);
        }
    }

    const itemClick = async (row) => {
        setIsItemClick(true);
        navigate(`/item-details/${row.uid}`, { state: row });
    }

    const searchItems = async (value) => {
        const allSearchData = await allItemsList?.filter(item => item?.search_field.toLowerCase().includes(value.toLowerCase()));
        setAllItems(allSearchData);
    }

    useEffect(() => {
        setSearch();
    }, [])

    useEffect(() => {
        setSearch();
    }, [site])
    
    return (
        <Box>
            <Box className='search-page-header'>
                <Box className='search-page-header-wrap'>
                    <Box className='search-page-header-content'>
                        <Link className='search-back' style={{ textDecoration: 'none' }} to="/">
                            <IconButton><Iconify width={24} icon="eva:arrow-back-outline" /></IconButton>
                            <span className='search-back-btn'>Back</span>
                        </Link>
                        <Box className='search-page-txt-wrap'>
                            <Iconify color="#000" icon={'carbon:search'} className='search-ico' />
                            <input onKeyUp={(event) => searchItems(event.target.value)} autoFocus type="text" className='search-page-txt' placeholder='Search...' />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className='search-page-body'>
                <Box className='search-page-body-wrap'>

                    {allItems.map((item) => {
                        return (
                            <ItemList
                                key={item.uid}
                                item={item}
                                itemClick={itemClick}
                            />
                        )
                    })}

                    <Box style={{
                        paddingBottom: 50
                    }}></Box>

                </Box>
            </Box>
            <EnquiryFormDialog />
        </Box>
    );
}

export default Search;