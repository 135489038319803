import React from 'react';
import {
    ListItemButton,
    ListItemText,
    List,
    Collapse
} from '@mui/material';
import Iconify from '../iconify/Iconify';

function FaqList({ row }) {
    const [faqOpen, setFaqOpen] = React.useState(false);

    const handleFaqClick = () => {
        setFaqOpen(!faqOpen);
    };

    return (
        <>
            <List>
                <ListItemButton onClick={handleFaqClick} style={{
                    borderRadius: 6
                }}>
                    <ListItemText primaryTypographyProps={{
                        fontSize: 15,
                        fontWeight: 600
                    }} primary={row?.question} />
                    {faqOpen ? <Iconify icon={'bi:chevron-down'} /> : <Iconify icon={'bi:chevron-right'} />}
                </ListItemButton>
                <Collapse in={faqOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 2 }} >
                            <ListItemText primaryTypographyProps={{
                                fontSize: 15
                            }} primary={row?.answer} />
                        </ListItemButton>
                    </List>
                </Collapse>
            </List>
        </>
    );
}

export default FaqList;