import React from 'react';
import { Avatar, Button, IconButton, Stack } from '@mui/material';
import { FiMoreVertical } from "react-icons/fi";
import Rating from '@mui/material/Rating';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import "./Review.css";
import Iconify from '../iconify/Iconify';
import ReviewPopover from '../review-popover/ReviewPopover';
import { useSelector } from 'react-redux';
import { IMAGE_URL, NO_USER_IMAGE } from '../../constants/Images';

function Review({ review, isUserReview }) {
    const storeSelector = useSelector((state) => state.sStore);
    const configs = storeSelector.configs;
    const site = storeSelector.site;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <div className='sUserReview'>
                <div className='sUserReviewD'>
                    <div className='sUserReviewImg'>
                        <Avatar
                            variant="circular"
                            alt={review?.user_name}
                            src={review?.profile_pic ? `${IMAGE_URL}user/thumbnail?img_id=${review?.profile_pic}` : NO_USER_IMAGE}
                            style={{
                                height: 55,
                                width: 55
                            }} />
                    </div>
                    <div className='sUserReviewName'>{review?.user_name}</div>
                    <div>
                        {!isUserReview &&
                            <ReviewPopover review={review} />
                        }
                    </div>
                </div>
                <div className='sUserReviewRate'>
                    <div>
                        <Rating name="read-only" value={review?.rating} readOnly />
                    </div>
                    <div className='sUserReviewPosted'>
                        {review?.created_at}
                    </div>
                </div>
                <div className='sUserReviewComment'>
                    {review?.user_review}
                </div>
                {/* <div style={{
                    marginTop: 10
                }}>
                    <Button size="small" variant="text" startIcon={<Iconify icon="ri:reply-fill" />} style={{
                        color: 'rgb(0, 171, 85)'
                    }}>
                        Reply
                    </Button>
                </div> */}
                {review?.store_review && !isUserReview &&
                    <div className='sOwnerReview'>
                        <div className='sOwnerReviewHead'>
                            <div className='sOwnerReviewHeadName'>{review?.store_name}</div>
                            <div className='sOwnerReviewPosted'>{review?.store_review_create_at}</div>
                        </div>
                        <div className='sOwnerReviewComment'>
                            {review?.store_review}
                        </div>
                        {/* <Stack direction='row' spacing={2} style={{
                            marginTop: 10
                        }}>
                            <Button size="small" variant="text" startIcon={<Iconify icon="eva:edit-fill" />} style={{
                                color: 'rgb(0, 171, 85)'
                            }}>
                                Edit
                            </Button>
                            <Button size="small" variant="text" startIcon={<Iconify icon="eva:trash-2-outline" />} style={{
                                color: '#FF4842'
                            }}>
                                Delete
                            </Button>
                        </Stack> */}
                    </div>
                }

                <div className='clearfix'></div>
            </div>
        </div>
    );
}

export default Review;