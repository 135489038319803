import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import PostCard from '../../components/postCard/PostCard';
import "./Posts.css";

function Posts(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    return (
        <Box>
            <Helmet>
                <style>{`body { background-color: ${configs?.pageBackground} }`}</style>
                <title>{site?.store_details?.store_name ? `${site?.store_details?.store_name} Posts` : 'Posts'}</title>
            </Helmet>
            <Box className='page-container'>
                <Box className='post-page'>
                    <Typography className='home-category-title post-page-title'>Posts</Typography>
                    {site?.posts &&
                        <Box className='posts-wrap'>
                            {site.posts.map((post) => {
                                return (
                                    <PostCard
                                        key={post.uid}
                                        post={post} />
                                )
                            })}
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default Posts;