import { Avatar, Box, Button, Rating } from '@mui/material';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { IoStar } from 'react-icons/io5';
import {  useSelector } from 'react-redux';
import { IMAGE_URL, NO_STORE_IMAGE } from '../../constants/Images';
import { filterStoreReview } from '../../reduxStore/actions/StoreActions';

function ReviewFilters({activeFilter, filterReviews}) {
    const storeSelector = useSelector((state) => state.sStore);
    const configs = storeSelector.configs;
    const site = storeSelector.site;
    const storeId = site?.store_details?.uid;
 
    return (
        <>
            <Helmet>
                <style>
                    {
                        `.reviewStarFilter ul li.active button { 
                        background-color: ${configs?.theme_bg_color}; 
                    color: ${configs?.theme_color} !important;
                    border: 1px solid ${configs?.theme_color} !important;
                    }`
                    }
                </style>
            </Helmet>

            <div className='reviewDialogHeader'>
                <div className='reviewDialogImg'>
                    <Avatar
                        variant="square"
                        alt={site?.store_details?.store_name}
                        src={site?.store_details?.logo ? `${IMAGE_URL}store/logo/thumbnail?id=${storeId}&img_id=${site?.store_details.logo}` : NO_STORE_IMAGE}
                        style={{
                            height: 60,
                            width: 60,
                            borderRadius: 10
                        }}
                    />
                </div>
                <div className='reviewDialogName'>
                    <div>{site?.store_details?.store_name}</div>
                    <div><Rating name="read-only" precision={0.5} value={site?.reviews?.rating ? site?.reviews?.rating : 0} readOnly size='small' /></div>
                </div>
            </div>
            <div className='reviewStarFilter'>
                <Box className='reviewStarWrap'>
                    <ul>
                        <li className={activeFilter === 'all' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('all')}
                            >
                                All
                            </Button>
                        </li>
                        <li className={activeFilter === 'positive' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('positive')}
                            >
                                Positive
                            </Button>
                        </li>
                        <li className={activeFilter === 'critical' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('critical')}
                            >
                                Critical
                            </Button>
                        </li>
                        <li className={activeFilter === '5' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('5')}
                            >
                                5 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '4' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('4')}
                            >
                                4 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '3' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('3')}
                            >
                                3 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '2' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('2')}
                            >
                                2 <IoStar />
                            </Button>
                        </li>
                        <li className={activeFilter === '1' ? 'active' : ''}>
                            <Button
                                variant='outlined'
                                className='reStarFilterType'
                                onClick={() => filterReviews('1')}
                            >
                                1 <IoStar />
                            </Button>
                        </li>
                    </ul>
                </Box>
                {/* <div className='reviewFilterMore'>
                <div>Most recent</div> <Button><IoFilterOutline /></Button>
            </div> */}
            </div>
        </>
    );
}

export default ReviewFilters;