import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography, Container, Card, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { auth, sendOtp, setUserSession, updateName } from '../../reduxStore/actions/AuthActions';
import { SHOW_LOADING, SHOW_LOGING_DIALOG } from '../../reduxStore/actions/AuthTypes';
import AppButton from '../app-button/AppButton';
import { Link, useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import AppDialogSlide from '../app-dialog-slide/AppDialogSlide';
import "./SignIn.css";
import { getReviewRecommendation } from '../../reduxStore/actions/StoreActions';

function SignIn() {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const storeId = site?.store_details?.uid;
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const authSelector = useSelector((state) => state.sAuth);
    const isLoading = authSelector.isLoading;
    const [step, setStep] = useState(1);
    const [otp, setOtp] = useState('');
    const [mobile, setMobile] = useState('');
    const [dialogTitle, setDialogTitle] = useState('Login');
    const [name, setName] = useState('');
    const [countryCode, setcountryCode] = useState('91');
    const dispatch = useDispatch();
    const otpReferenceId = authSelector.userLogin.otp_reference_id;
    const [timerCount, setTimer] = useState(60);

    const handleOtpChange = (otp) => {
        setOtp(otp);
        if (otp.length === 6) {
            //handleOtpClick();
        }
    }

    const handleLoginClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            setOtp('');
            const res = await dispatch(auth(countryCode, mobile));
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            setDialogTitle('OTP Verification');
            setStep(2);
            setTimer(60);
            handleSetTimer();
            enqueueSnackbar('Otp sent successfully.', { variant: 'success' });
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleOtpClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            const res = await dispatch(sendOtp(countryCode, mobile, otp, otpReferenceId));
            await dispatch(setUserSession(res));
            await dispatch(getReviewRecommendation({ store_id: storeId }));
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (res?.data?.name && res.data.name !== '') {
                dispatch({
                    type: SHOW_LOGING_DIALOG,
                    payload: false
                });
                enqueueSnackbar('Successfully logged in.', { variant: 'success' });
            } else {
                setStep(3);
                setDialogTitle('Enter Name');
            }
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleNameClick = async () => {
        try {
            dispatch({
                type: SHOW_LOADING,
                payload: true
            });
            const res = await dispatch(updateName(name));
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: false
            });
            enqueueSnackbar('Successfully logged in.', { variant: 'success' });
        } catch (err) {
            dispatch({
                type: SHOW_LOADING,
                payload: false
            });
            if (err?.errors) {
                enqueueSnackbar(JSON.parse(err?.errors).message, { variant: 'error' });
            }
        }
    }

    const handleSetTimer = () => {
        let interval = setInterval(() => {
            setTimer(lastTimerCount => {
                lastTimerCount <= 1 && clearInterval(interval)
                return lastTimerCount - 1
            })
        }, 1000)
        return () => clearInterval(interval)
    }

    const handleDialogClickClose = () => {
        if (step === 3) {
            enqueueSnackbar('Please enter name.', { variant: 'error' });
        } else {
            dispatch({
                type: SHOW_LOGING_DIALOG,
                payload: false
            });
        }
    }

    return (
        <>
            <AppDialogSlide
                isOpen={true}
                id={'store_login_sm'}
                handleClickClose={handleDialogClickClose}
                title={dialogTitle}
            >
                <Box className='l-page'>
                    <Box>
                        <Box className='login-wrap'>
                            <Box>
                                {step === 1 &&
                                    <Box className='login-content'>
                                        <Box>
                                            <TextField
                                                autoComplete='off'
                                                inputProps={{
                                                    style: { fontSize: 16, fontWeight: 500 }
                                                }} fullWidth
                                                autoFocus
                                                value={mobile}
                                                label="Email or mobile number"
                                                variant="outlined"
                                                onChange={event => setMobile(event.target.value)}
                                            />
                                        </Box>
                                        <Box>
                                            <AppButton
                                                disabled={isLoading}
                                                isLoading={isLoading}
                                                onClick={handleLoginClick}
                                                fullWidth size='large'
                                                variant="contained"
                                                style={{
                                                    background: configs?.themeBgColor,
                                                    color: configs?.themeColor
                                                }}
                                            >
                                                Send OTP
                                            </AppButton>
                                        </Box>
                                    </Box>
                                }

                                {step === 2 &&
                                    <Box className='login-content'>
                                        {false &&
                                            <Typography className='l-error'>Incorrect OTP. Please try again.</Typography>
                                        }
                                        <Box>
                                            <Typography>
                                                <span style={{
                                                    color: 'gray',
                                                    fontSize: 14
                                                }}>Enter verfication code sent to you at</span> +91-{mobile}
                                                <Link style={{
                                                    paddingLeft: 5,
                                                    fontSize: 14,
                                                    color: '#146eb4'
                                                }} onClick={() => setStep(1)}>Edit</Link>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box className='verify-help-text'>
                                                <Typography className='f-14'>Code</Typography>
                                                <Typography className='f-14'>
                                                    Didn't get the code?
                                                    <Link className='resend-link'
                                                        onClick={timerCount <= 0 && handleLoginClick}
                                                        style={{
                                                            color: timerCount > 0 && '#ccc',
                                                            cursor: timerCount > 0 && 'default',
                                                        }}
                                                    >
                                                        Resend OTP {timerCount > 0 && timerCount}
                                                    </Link>
                                                </Typography>
                                            </Box>

                                            <OtpInput
                                                shouldAutoFocus
                                                inputStyle='otp-input-style'
                                                focusStyle='otp-focus-style'
                                                value={otp}
                                                onChange={handleOtpChange}
                                                numInputs={6}
                                                isInputNum={true}
                                            />
                                        </Box>
                                        <Box>
                                            <AppButton
                                                disabled={isLoading}
                                                isLoading={isLoading}
                                                onClick={handleOtpClick}
                                                fullWidth size='large'
                                                variant="contained"
                                                style={{
                                                    background: configs?.themeBgColor,
                                                    color: configs?.themeColor
                                                }}
                                            >
                                                Verify
                                            </AppButton>
                                        </Box>
                                        <Box>
                                        </Box>
                                    </Box>
                                }

                                {step === 3 &&
                                    <Box className='login-content'>
                                        <Box>
                                            <TextField
                                                autoComplete='off'
                                                inputProps={{
                                                    style: { fontSize: 16, fontWeight: 500 }
                                                }}
                                                fullWidth
                                                autoFocus
                                                value={name}
                                                label="Enter your full name"
                                                variant="outlined"
                                                onChange={event => setName(event.target.value)}
                                            />
                                        </Box>
                                        <Box>
                                            <AppButton
                                                disabled={isLoading}
                                                isLoading={isLoading}
                                                onClick={handleNameClick}
                                                fullWidth size='large'
                                                variant="contained"
                                                style={{
                                                    background: configs?.themeBgColor,
                                                    color: configs?.themeColor
                                                }}
                                            >
                                                Save
                                            </AppButton>
                                        </Box>
                                    </Box>
                                }

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </AppDialogSlide>
        </>
    );
}

export default SignIn;