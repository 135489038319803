import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import AppSmTabs from '../components/app-sm-tabs/AppSmTabs';
import EnquiryFormDialog from '../components/enquiry-form-dialog/EnquiryFormDialog';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import SignIn from '../components/sign-in/SignIn';
import "./SiteLayout.css";
import NotFound from '../pages/not-found/NotFound';

function SiteLayout(props) {
    const authSelector = useSelector((state) => state.sAuth);
    const loginDialog = authSelector.isLoginDialog;
    return (
        <div>
            {/* <NotFound /> */}
            <Header />
            <Outlet />
            <Box className='footer-site'>
                <Footer />
            </Box>
            <AppSmTabs />
            {loginDialog &&
                <SignIn />
            }
            <EnquiryFormDialog />
        </div>
    );
}

export default SiteLayout;