import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import "./AppSmTabs.css";
import Iconify from '../iconify/Iconify';
import { AppBar, Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AppDialogSlide from '../app-dialog-slide/AppDialogSlide';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../reduxStore/actions/AuthActions';
import { SHOW_LOGING_DIALOG } from '../../reduxStore/actions/AuthTypes';
import { Helmet } from 'react-helmet';

function AppSmTabs(props) {
    const storeSelector = useSelector((state) => state.sStore);
    const site = storeSelector.site;
    const configs = site?.store_details?.theme_config ? JSON.parse(site?.store_details?.theme_config) : {};
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
    const [profileDialog, setProfileDialog] = React.useState(false);
    const location = useLocation();
    const pathLocation = location.pathname;
    const auth = useSelector((state) => state.sAuth);
    const accessToken = auth?.userDetails?.access_token;
    const dispatch = useDispatch();

    const handleTabChange = () => {
        switch (location.pathname) {
            case '/':
                setValue(0);
                break;
            case '/about':
                setValue(1);
                break;
            case '/offers':
                setValue(2);
                break;
            case '/posts':
                setValue(3);
                break;
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                return navigate('/', { replace: true });
            case 1:
                return navigate('/about', { replace: true });
            case 2:
                return navigate('/offers', { replace: true });
            case 3:
                return navigate('/posts', { replace: true });
            case 4:
                setProfileDialog(true);
        }
    };

    const handleDialogClickClose = (index) => {
        setProfileDialog(false);
    };

    const handleAccountClick = (page) => {
        setProfileDialog(false);
        switch (page) {
            case 'login':
                dispatch({
                    type: SHOW_LOGING_DIALOG,
                    payload: true
                });
                break;
            case 'profile':
                navigate('/user/profile', { replace: true });
                break;
            case 'logout':
                dispatch(logout());
                break;
        }
    };

    React.useEffect(() => {
        handleTabChange();
    }, [pathLocation]);

    return (
        <>
            <Helmet>
                <style>
                    {`.app-sm-tab.Mui-selected { color: ${configs?.primaryButtonBgColor} }`}
                </style>
            </Helmet>
            <Box className="app-sm-tabs">
                <AppBar position="fixed" sx={{ top: 'auto', bottom: 0, bgcolor: '#fff' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="fullWidth"
                        TabIndicatorProps={{
                            style: {
                                backgroundColor: configs?.primaryButtonBgColor
                            },
                        }}
                    >
                        <Tab className='app-sm-tab' icon={<Iconify icon={'material-symbols:home-outline'} />} label="Home" />
                        <Tab className='app-sm-tab' icon={<Iconify icon={'material-symbols:store-outline'} />} label="About" />
                        <Tab className='app-sm-tab' icon={<Iconify icon={'circum:discount-1'} />} label="Offers" />
                        <Tab className='app-sm-tab' icon={<Iconify icon={'material-symbols:post-add-sharp'} />} label="Posts" />
                        <Tab onClick={() => setProfileDialog(true)} className='app-sm-tab' icon={<Iconify icon={'carbon:user-avatar'} />} label="Account" />
                    </Tabs>
                </AppBar>
            </Box>

            {profileDialog &&
                <AppDialogSlide isOpen={profileDialog} id={'profile_dialog'} title={<>Account</>} footer={<></>} handleClickClose={handleDialogClickClose}>
                    <Box>
                        <MenuList>
                            {
                                !accessToken &&
                                <MenuItem onClick={() => handleAccountClick('login')} className='profile-dialog-list'>
                                    <ListItemIcon>
                                        <Iconify icon={'material-symbols:login'} />
                                    </ListItemIcon>
                                    <ListItemText>Login</ListItemText>
                                </MenuItem>
                            }
                            {
                                accessToken &&
                                <MenuItem onClick={() => handleAccountClick('profile')} className='profile-dialog-list'>
                                    <ListItemIcon>
                                        <Iconify icon={'iconoir:profile-circle'} />
                                    </ListItemIcon>
                                    <ListItemText>Profile</ListItemText>
                                </MenuItem>
                            }
                            {
                                accessToken &&
                                <MenuItem onClick={() => handleAccountClick('logout')} className='profile-dialog-list'>
                                    <ListItemIcon>
                                        <Iconify icon={'octicon:sign-in-24'} />
                                    </ListItemIcon>
                                    <ListItemText>Logout</ListItemText>
                                </MenuItem>
                            }
                        </MenuList>
                    </Box>
                </AppDialogSlide>
            }
        </>
    );
}

export default AppSmTabs;